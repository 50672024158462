.cardsmall {
  background: rgb(255, 255, 255);
  border:0 !important
}

.cardsmall+.panel {
    margin-top:0 !important
  }
  
  .cardsmall-header {
    position: relative;
    cursor: pointer;
    background-color: rgb(255, 255, 255) !important;
  }
  
  .arrowed[aria-expanded="true"]:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .cardsmall-header .panel-title{
    font-size: 14px;
  }
  .cardsmall-header .panel-title a::after {
    display: inline-block;
    content: "";
    border-top: .1rem solid #d5d0e4;
    width: 88%;
    margin: 0 1rem;
    transform: translateY(-0.5rem);
}
.cardsmall-header .panel-title a::before {
    /* content: "\f078"; */
    content: "\f13a";
    display: inline-block;
    font-family: 'FontAwesome';
    position: relative;
    right: 0;
    transition: all 0.3s;
    color: #d5d0e4;
    font-size: 14px;
    margin-right: 5px;
}
.cardsmall-header .panel-title a[aria-expanded="true"]:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cardsmall .panel-body{
  border-top:0 !important;
}
.inv-view-table{
  background-color:#fff;
  margin-bottom:0
}
.inv-view-table > thead > tr > th {
  padding:3px !important
}
.inv-view-table .image-col{
  width: 5%
}
.inv-thumb {
  margin: 5px;
  /* border: 1px solid #d5d0e4; */
  box-shadow: 0px 1px 8px 3px rgba(0,0,0,.075);
  border-radius: 7px;
}
.inv-thumb:hover {
  box-shadow: 0px 2px 10px 5px rgba(0,0,0,.075);
}

.inv-thumb img {
  padding: 10px;
  width: 80px;
}
